<template>
   <div class="shop mobile">
        <!-- <div>工厂id{{$route.params.compid}} 分享者id{{$route.params.userid}}</div> -->
        <div class="shop-header">
            <div @click="viewZizhi()" v-if="comp && comp.name" class="shop-header-title">{{comp.name}}</div>
            <div class="shop-search">
                <div class="search">
                    <i class="iconfont icon-sousuo"></i>
                    <div>
                        <input type="text" placeholder="发布的内容">
                    </div>
                </div>
            </div>
        </div>
        <div class="shop-scroll">
            <div class="shop-class">
                <div v-for="(item,idx) in cats" :key="idx">
                    <div class="bg_img"
                    :style="{backgroundImage: 'url(' + imgUrl+item.data.icon + ')'}"></div>
                    <div>{{item.name}}</div>
                </div>
                
            </div>
            <div class="shop-content">
                <div @click="shopDetail(item.id)" v-for="(item,idx) in list" :key="idx" class="shop-item">
                    <div class="shop-item-top">
                        <div class="left">
                            <div v-if="item.photoRender && item.photoRender.length>0" class="bg_img" 
                                :style="{backgroundImage: 'url(' + imgUrl+item.photoRender[0]+'!width_250px' + ')'}" alt=""></div>
                        </div>
                        <div class="right">
                            <h3 class="ellipsis">{{item.name}}</h3>
                            <p class="font14-grey1">价格：<span style="color:red;">￥{{item.price}}</span></p>
                            <!-- <p>附件一：角柜</p>
                            <p>附件一：角柜</p> -->
                        </div>
                    </div>
                    <div class="shop-item-bottom">
                        <div v-for="(item1,idx1) in item.photoRender" :key="idx1" class="bg_img" 
                        :style="{backgroundImage: 'url(' + imgUrl+item1+'!width_250px' + ')'}" alt=""></div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import { BASE_URL,IMG_URL_PRE } from '@/config';
    import { fetchTree } from '@/util';
    import { moShopList,getShopCats } from '@/service/mobile';
    import { getCompBaseInfoById } from '@/service/company';
    export default {
        components: {},
        data() {
            return {
                imgUrl:IMG_URL_PRE,
                queryOpt:{
                    where:{
                        ids:null,
                        key:null,
                        isMaster:true,
                        catIds:null,
                        compIds:[this.$route.params.compid],
                    },
                    page:{
                        pageNumber:1,
                        pageCount:16,
                    },
                    order: [
                        ["createdAt","desc"]
                    ],
                },
                total:0,
                list:[],
                cats: [],
                title:"",
                comp:null,
            }
        },
        async created() {
            this.cats = fetchTree(await getShopCats(), null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
            // console.log(this.cats);
            this.comp = await getCompBaseInfoById(this.$route.params.compid);
            this.title = this.comp.name;
            document.title = this.title;
            this.refreshItems();
        },
        methods: {
            refreshItems() {
                console.log('refreshItems');
                moShopList(this.queryOpt).then(rst => {
                    if (rst.rows && rst.rows.length) {
                        this.total=rst.count;
                        this.list = rst.rows;
                    }
                    // console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            shopDetail(id){
                this.$router.push('/mobile/shop/detail/'+this.$route.params.compid+'/' + this.$route.params.userid+'/'+id);
                // location.href = this.shop_detail;
            },
            viewZizhi(){
                this.$router.push('/mobile/facIntro/'+this.$route.params.compid);
            },
        }
    }
</script>
<style scoped src="./style.css"></style>
<style scoped>
    .shop {
        width: 100%;
        height: 100%;
        font-size: 0;
        overflow: hidden;
    }
    .shop-header {
        width: 100%;
        height: 92px;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
    }
    .shop-header-title {
        width: 100%;
        height: 46px;
        line-height: 46px;
        text-align: center;
        background: #424C5D;
        font-size: 17px;
        color: #FFFFFF;
        cursor: pointer;
    }
    .shop-search {
        width: 100%;
        height: 46px;
        background: #424C5D;
        overflow: hidden;
    }
    .shop-search .search {
        width:calc(100% - 28px);
        height:30px;
        margin: 8px auto 8px;
        background: #fff;
        border-radius: 20px;
        position: relative;
    }
    .search i {
        padding: 0 7px 0 11px;
        line-height: 32px;
        font-size: 14px;
        color: #909399;
    }
    .search>div {
        width: calc(100% - 38px);
        padding: 7px 0;
        position: absolute;
        top: 0;
        right: 0;
    }
    .search input {
        display: inline-block;
        vertical-align: top;
        width: 94%;
        height: 16px;
        border: none;
        outline: none;
        font-size: 14px;
        color: #333;
    }
    .search input::placeholder {
        font-size: 13px;
        color: #CCCCCC;
    }
    .shop-scroll {
        height: calc(100% - 92px);
        overflow-y: auto;
        margin-top: 92px;
    }
    .shop-class {
        width: calc(100% - 20px);
        margin: 0 auto;
        padding: 13px 0;
    }
    .shop-class>div {
        display: inline-block;
        vertical-align: top;
        width: calc(100% / 4);
        text-align: center;
    }
    .shop-class>div>div:first-child {
        width: 40px;
        height: 40px;
        /* border-radius: 50%; */
        margin: 0 auto;
    }
    .shop-class>div>div:last-child {
        font-size: 12px;
        color: #4D4D4D;
        height: 28px;
        line-height: 28px
    }
    .deepBlue {
        background: #5074EE;
    }
    .purple {
        background: #9850EE;
    }
    .pink {
        background: #EE5087;
    }
    .red {
        background: #EE5050;
    }
    .wathetBlue {
        background: #50BCEE;
    }
    .shop-content {
        width: calc(100% - 24px);
        margin: 0 auto;
    }
    .shop-content .shop-item {
        margin-bottom: 10px;
    }
    .shop-item .shop-item-top{
        padding: 10px;
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 4px 0px rgba(202,202,202,0.5);
        border-radius:10px;
        margin-bottom: 10px;
    }
    .shop-item .shop-item-top>div {
        display: inline-block;
        vertical-align: top;
    }
    .shop-item .shop-item-top .left>div {
        width: 100px;
        height: 100px;
        margin-right: 10px;
    }
    .shop-item .shop-item-top .right {
        width: calc(100% - 110px);
    }
    .shop-item .shop-item-top .right>h3 {
        font-size: 14px;
        font-weight: 600;
        color: #333333;
        margin-bottom: 11px;
    }
    .shop-item .shop-item-top .right>p:nth-child(2) {
        font-size: 14px;
        color: #808080;
        line-height: 20px;
        margin-bottom: 10px;
    }
    .shop-item .shop-item-top .right>p {
        font-size: 12px;
        line-height: 16px;
        color: #808080;
        margin-bottom: 6px;
    }
    .shop-item .shop-item-top .right>p:last-child {
        margin-bottom: 0;
    }
    .shop-item .shop-item-bottom {
        background:rgba(255,255,255,1);
        box-shadow:0px 0px 4px 0px rgba(202,202,202,0.5);
        border-radius:10px;
        padding: 5px;
    }
    .shop-item-bottom>div {
        width: 77px;
        height: 77px;
        margin: 5px;
    }
</style>